import { map } from "lodash";

export function parseCssToObj(cssText) {
  const cssObj = {};
  const rules = cssText.split(";");

  rules.forEach((rule) => {
    const [property, value] = rule.split(":");
    if (property && value) {
      cssObj[property.trim()] = value.trim();
    }
  });

  return cssObj;
}

export function cleanUrl(url) {
  // Split URL into base and query string
  const [baseUrl, queryString] = url.split("?");

  if (!queryString) {
    return url; // No query string, return as is
  }

  // Parse query string into key-value pairs
  const params = new URLSearchParams(queryString);
  const filteredParams = {};

  // Filter out null or other falsy values
  params.forEach((value, key) => {
    if (value !== "null" && value !== "undefined" && value) {
      filteredParams[key] = value;
    }
  });

  // Use lodash to create a cleaned query string
  const newQueryString = map(
    filteredParams,
    (value, key) => `${key}=${value}`
  ).join("&");

  // Reconstruct the URL
  return newQueryString ? `${baseUrl}?${newQueryString}` : baseUrl;
}
